<template>
  <div class="swap">
    <headbar :title="$t('Swap')" bgColor="#080535"></headbar>

    <div>
      <div class="row justify-between">
        <div class="name">{{ wallet1.name }}</div>
        <div>Balance: <span class="main-color">{{ wallet1.balance }}</span></div>
      </div>
      <div class="input-wrap q-mt-xs">
        <q-input borderless v-model="num" dense :input-style="{ color: '#fff' }" style="width: 100%;"
          :placeholder="$t('请输入数量')" />
      </div>
    </div>
    
    <div class="row justify-center q-py-lg">
      <img src="@/assets/img/wallet/swap.png" width="30" @click="exchangeCoin" />
    </div>

    <div>
      <div class="row justify-between">
        <div class="name">{{ wallet2.name }}</div>
        <div>Balance: <span class="main-color">{{ wallet2.balance }}</span></div>
      </div>
      <div class="input-wrap q-mt-xs">
        {{ num * bili }}
      </div>
    </div>

    <div class="row justify-between q-mt-lg">
      <div>Price</div>
      <div>1{{ wallet1.name }} = {{ bili }}{{ wallet2.name }}</div>
    </div>

    <div class="btn-wrap">
      <div class="btn" @click="approve">授权</div>
      <div class="btn" v-if="wallet1.userAllowance > num">Swap</div>
    </div>

    <!-- txid -->
    <q-dialog v-model="showTxid" persistent>
      <q-card class="q-dialog-plugin" style="min-width: 350px">
        <q-card-section>
          <div class="text-body1">{{ $t('交易广播成功') }}</div>
          <div class="text-body2">Txid</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div class="input-wrap">
            <q-input borderless v-model="txid" dense :input-style="{ color: '#fff' }" style="width: 100%;" readonly>
              <template v-slot:append>
                <q-icon name="content_copy" style="font-size: 16px;" color="white" @click="copy(txid)" />
              </template>
            </q-input>
          </div>
        </q-card-section>
        <div style="padding: 0 16px 16px;">
          <div class="btn" style="margin: 0;" @click="showTxid = false">{{ $t('确定') }}</div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import Web3Helper from "@/assets/js/web3helper.js";
import CustomComponent from "@/components/CustomComponent";

export default {
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      wallet1: ref({
        name: 'USDT',
        balance: 0,
        userAllowance: 0
      }),
      wallet2: ref({
        name: 'LBD',
        balance: 0,
        userAllowance: 0
      }),
      bili: ref(1),

      contractInfoUSDT: ref(null),
      contractInfoLBD: ref(null),
      contractInfoApproveContract: ref(null),
      contractInfoPayContract: ref(null),
      contractInfoSwapRouterV3: ref(null),

      usdtWeb3Helper: ref({}),
      lbdWeb3Helper: ref({}),
      payContractWeb3Helper: ref({}),
      swapRouterV2Web3Helper: ref({}),
      swapRouterV3Web3Helper: ref({}),

      showTxid: ref(false),
      txid: ref("")
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getInfo()
  },
  methods: {
    exchangeCoin() {
      let _wallet = this.wallet1
      this.wallet1 = this.wallet2
      this.wallet2 = _wallet
    },
    // 设置对应对象余额
    setWalletBalance(walletName, balance) {
      if (this.wallet1.name === walletName) {
        this.wallet1.balance = balance;
      } else if (this.wallet2.name === walletName) {
        this.wallet2.balance = balance;
      }
    },
    // 设置对应对应授权额度
    setUserAllowance(walletName, userAllowance) {
      if (this.wallet1.name === walletName) {
        this.wallet1.userAllowance = userAllowance;
      } else if (this.wallet2.name === walletName) {
        this.wallet2.userAllowance = userAllowance;
      }
    },
    getInfo() {
      let _this = this
      _this.$request.post(
        "api/EthContract/Info",
        {},
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
          }
          let data = res.data.data

          _this.contractInfoUSDT = data.contractList.find(item => item.id == 1);
          _this.usdtWeb3Helper = new Web3Helper(_this.contractInfoUSDT.abi, _this.contractInfoUSDT.address);

          _this.contractInfoLBD = data.contractList.find(item => item.id == 14);
          _this.lbdWeb3Helper = new Web3Helper(_this.contractInfoLBD.abi, _this.contractInfoLBD.address);
          
          _this.contractInfoApproveContract = data.contractList.find(item => item.id == 102);

          _this.contractInfoPayContract = data.contractList.find(item => item.id == 103);
          _this.payContractWeb3Helper = new Web3Helper(_this.contractInfoPayContract.abi, _this.contractInfoPayContract.address);

          var swapRouterV2ContractInfo = data.contractList.find(item => item.id == 100)
          _this.swapRouterV2Web3Helper = new Web3Helper(swapRouterV2ContractInfo.abi, swapRouterV2ContractInfo.address);

          _this.contractInfoSwapRouterV3 = data.contractList.find(item => item.id == 101)
          _this.swapRouterV3Web3Helper = new Web3Helper(_this.contractInfoSwapRouterV3.abi, _this.contractInfoSwapRouterV3.address);

          _this.balanceOf(_this.us.address);
          _this.allowance(_this.us.address)
        }
      )
    },
    allowance(address) {
      let _this = this;
      _this.usdtWeb3Helper.contractAllowance(address, _this.contractInfoSwapRouterV3.address).then((wei) => {
        let usdtuserAllowance = _this.usdtWeb3Helper.fromWei(wei, _this.contractInfoUSDT.decimals);
        _this.setUserAllowance('USDT', usdtuserAllowance)
      });
      _this.lbdWeb3Helper.contractAllowance(address, _this.contractInfoApproveContract.address).then((wei) => {
        let lbduserAllowance = _this.lbdWeb3Helper.fromWei(wei, _this.contractInfoLBD.decimals);
        _this.setUserAllowance('LBD', lbduserAllowance)
      });
    },
    balanceOf(address) {
      let _this = this;
      _this.usdtWeb3Helper.contractBalanceOf(address).then((wei) => {
        let usdtbalance = _this.usdtWeb3Helper.fromWei(wei, _this.contractInfoUSDT.decimals);
        _this.setWalletBalance('USDT', usdtbalance)
      });

      _this.lbdWeb3Helper.contractBalanceOf(address).then((wei) => {
        let lbdbalance = _this.lbdWeb3Helper.fromWei(wei, _this.contractInfoLBD.decimals);
        _this.setWalletBalance('LBD', lbdbalance)
      });
    },
    approve() {
      let _this = this

      let web3Helper;

      if (this.wallet1.name == 'USDT') {
        web3Helper = _this.usdtWeb3Helper;
      } else if(this.wallet1.name == 'LBD') {
        web3Helper = _this.lbdWeb3Helper;
      }

      let payWei = web3Helper.web3.utils.toBN('2').pow(web3Helper.web3.utils.toBN('256')).sub(web3Helper.web3.utils.toBN('1'));

      _this.$q.loading.show({
        message: _this.$i18n.t("正在交易中...")
      })

      // let approveAddress;
      // if (lx == 1) {
      //   approveAddress = _this.contractInfoSwapRouterV3.address;
      // } else {
      //   approveAddress = _this.contractInfoApproveContract.address;
      // }
      let approveAddress = _this.contractInfoApproveContract.address;

      web3Helper.contractApprove(_this.us.address, approveAddress, payWei.toString()).then((txid) => {
        _this.$q.loading.hide();
        _this.allowance(_this.us.address)
      }, (err) => {
        _this.$q.loading.hide();
        //过滤4001,4001=拒绝签名
        if (err.code != 4001) {
          _this.$q.dialog({
            title: err.code,
            message: err.message,
          });
        }
      });
    },
  }
}
</script>

<style scoped>
.swap {
  padding: 15px 15px 70px;
  color: #fff;
}

.input-wrap {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #221c35;
}

.btn-wrap {
  display: flex;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.btn {
  margin: 0 8px;
  flex: 1;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 10px;
}

.name {
  font-size: 16px;
  font-weight: bold;
}

.main-color {
  color: #e76efa;
}
</style>